<div class="tool-box-wrapper">
    <div class="tool-box__image">
        <img src="../assets/main/home/map.jpg" alt="Photo of a map" />
    </div>
    <div class="tool-box" [class]="'tool-box-color__' + toolBoxColor">
        <div class="tool-box__header">
            <icon [icon]="icon" [enableFill]="false" [enableStroke]="true"></icon>
            <h2>{{ toolBoxTitle }}</h2>
        </div>
        <div class="tool-box__body">
            <p>{{ toolBoxContent }}</p>
            <a [routerLink]="[link]" class="btn btn-sm">{{ buttonTitle }}</a>
        </div>
    </div>
</div>
