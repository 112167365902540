import { FlagEnum } from "../generated/enum/flag-enum";
import { PermissionEnum } from "../generated/enum/permission-enum";
import { UserDto } from "../generated/model/user-dto";
import { RightsEnum } from "../models/enums/rights.enum";

export class AuthorizationHelper {
    public static hasFlag(flagEnum: FlagEnum, user: UserDto): boolean {
        const flagName = FlagEnum[flagEnum];
        return user.Flags[flagName];
    }

    public static hasRolePermission(permissionEnum: PermissionEnum, rightsEnum: RightsEnum, user: UserDto): boolean {
        const permissionName = PermissionEnum[permissionEnum];

        if (user.Rights[permissionName][rightsEnum]) {
            return true;
        }

        return false;
    }
}
