import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { environment } from "src/environments/environment";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { AlertDisplayComponent } from "src/app/shared/components/alert-display/alert-display.component";
import { DataCardComponent } from "src/app/shared/components/data-card/data-card.component";
import { ToolBoxComponent } from "src/app/shared/components/tool-box/tool-box.component";
import { ContentSectionComponent } from "src/app/shared/components/content-section/content-section.component";
import { WithRoleDirective } from "../../../shared/directives/site-role-check.directive";
import { NgIf, AsyncPipe } from "@angular/common";
import { WithFlagDirective } from "../../../shared/directives/with-flag.directive";

@Component({
    selector: "app-home-index",
    templateUrl: "./home-index.component.html",
    styleUrls: ["./home-index.component.scss"],
    standalone: true,
    imports: [WithFlagDirective, NgIf, WithRoleDirective, ContentSectionComponent, ToolBoxComponent, DataCardComponent, AlertDisplayComponent, AsyncPipe],
})
export class HomeIndexComponent implements OnInit, OnDestroy {
    readonly RoleEnum = RoleEnum;
    public currentUser$: Observable<UserDto>;

    public unassignedFlagEnum: FlagEnum = FlagEnum.ShowUnassignedMessage;
    public contactEmail = environment.contactEmail;

    public CustomRichTextTypeEnum = CustomRichTextTypeEnum;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            //We're logging in
            if (params.hasOwnProperty("code")) {
                this.router.navigate(["/signin-oidc"], { queryParams: params });
                return;
            }

            if (localStorage.getItem("loginOnReturn")) {
                localStorage.removeItem("loginOnReturn");
                this.authenticationService.login();
            }

            //We were forced to logout or were sent a link and just finished logging in
            if (sessionStorage.getItem("authRedirectUrl")) {
                this.router.navigateByUrl(sessionStorage.getItem("authRedirectUrl")).then(() => {
                    sessionStorage.removeItem("authRedirectUrl");
                });
            }

            this.currentUser$ = this.authenticationService.getCurrentUser();
        });
    }

    ngOnDestroy(): void {}

    public login(): void {
        this.authenticationService.login();
    }

    public logout(): void {
        this.authenticationService.logout();
    }

    public signUp(): void {
        this.authenticationService.signUp();
    }

    public platformLongName(): string {
        return environment.platformLongName;
    }
}
