<div class="popup-wrapper" [loadingSpinner]="{ isLoading, loadingHeight: 225 }">
    <ng-container *ngIf="data$ | async as data">
        <div class="popup-header">
            <water-resource-inventory-area-title
                [waterResourceInventoryAreaName]="waterResourceInventoryArea.WaterResourceInventoryAreaName"
                [waterResourceInventoryAreaID]="waterResourceInventoryArea.WaterResourceInventoryAreaID.toString()"></water-resource-inventory-area-title>
        </div>

        <div class="popup-body">
            <div>
                <dl>
                    <dd>
                        <strong>Total WRIA Acres:</strong>
                        {{ waterResourceInventoryArea.TotalAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.ReachAcres">
                        <strong>Total Reach Acres:</strong>
                        {{ waterResourceInventoryArea.ReachAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.RiparianAcres">
                        <strong>Total Riparian Acres:</strong>
                        {{ waterResourceInventoryArea.RiparianAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.StreamMiles">
                        <strong>Total Stream Miles:</strong>
                        {{ waterResourceInventoryArea.StreamMiles | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.PercentTreeCover">
                        <strong>% Tree Cover:</strong>
                        {{ waterResourceInventoryArea.PercentTreeCover | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.PercentVegetated">
                        <strong>% Vegetated:</strong>
                        {{ waterResourceInventoryArea.PercentVegetated | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.PercentNonVegetated">
                        <strong>% Non-Vegetated:</strong>
                        {{ waterResourceInventoryArea.PercentNonVegetated | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="waterResourceInventoryArea.ImpassableFishPassageBarriers">
                        <strong>Impassable Fish Passage Barriers:</strong>
                        {{ waterResourceInventoryArea.ImpassableFishPassageBarriers | number }}
                    </dd>
                </dl>
            </div>
        </div>

        <div class="popup-footer" *ngIf="waterResourceInventoryArea.HasData">
            <a class="btn btn-primary btn-sm" [routerLink]="['/wrias/', waterResourceInventoryArea.WaterResourceInventoryAreaID]">View Watershed</a>
        </div>
    </ng-container>
</div>
