<div *ngIf="model">
    <page-header [pageTitle]="user?.FullName || (user?.FirstName || user?.LastName ? user?.FirstName + ' ' + user?.LastName : null)"> </page-header>

    <div class="page-body">
        <app-alert-display></app-alert-display>
        <form class="form grid-12" (ngSubmit)="onSubmit()" #editUserForm="ngForm">
            <div class="field g-col-8">
                <label class="required field-label">Role</label>
                <select name="RoleID" class="form-control" [(ngModel)]="model.RoleID" (change)="checkReceiveSupportEmails()" required>
                    <option value=""></option>
                    <option *ngFor="let role of roles" [value]="role.RoleID">
                        {{ role.RoleDisplayName }}
                    </option>
                </select>
            </div>

            <div class="field g-col-8" *ngIf="model.RoleID.toString() === '1'">
                <label class="d-block required field-label">Should Receive System Communications?</label>
                <label class="mr-3"><input type="radio" name="ReceiveSupportEmails" [(ngModel)]="model.ReceiveSupportEmails" [value]="true" /> Yes</label>
                <label><input type="radio" name="ReceiveSupportEmails" [(ngModel)]="model.ReceiveSupportEmails" [value]="false" /> No</label>
            </div>

            <div class="required-note g-col-8"><i class="fas fa-bolt"></i> Required field</div>

            <div class="field-actions g-col-8">
                <button type="submit" class="btn btn-secondary" [disabled]="!editUserForm.form.valid || isLoadingSubmit">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                    Save
                </button>
                <a class="btn btn-secondary-outline ml-2" routerLink="../">Cancel</a>
            </div>
        </form>
    </div>
</div>
