import { Component, Input } from "@angular/core";
import { default as vegaEmbed, VisualizationSpec } from "vega-embed";
import { LoadingDirective } from "../../../directives/loading.directive";
import { ChartData } from "../ChartData";
import { NgIf, NgForOf, PercentPipe, DecimalPipe } from "@angular/common";
import { SumPipe } from "src/app/shared/pipes/sum.pipe";
import { DefaultOrderKeyvaluePipe } from "src/app/shared/pipes/default-order-key-value.pipe";

@Component({
    selector: "salmon-distribution-bar-chart",
    standalone: true,
    imports: [LoadingDirective, DefaultOrderKeyvaluePipe, NgIf, NgForOf, PercentPipe, DecimalPipe, SumPipe],
    templateUrl: "./salmon-distribution-bar-chart.component.html",
    styleUrl: "./salmon-distribution-bar-chart.component.scss",
})
export class SalmonDistributionBarChartComponent {
    @Input() chartData: ChartData[];
    @Input() chartHeight: number = 400;
    @Input() isLoading: boolean = false;

    public chartID: string = "salmonDistributionBarChart";
    public colorRange: Map<string, string> = new Map([
        ["Documented", "#234F81"],
        ["Presumed", "#5E99D2"],
        ["Gradient Accessible", "#9EBBD7"],
    ]);

    public getTotalValueForGroup(groupName: string): number {
        return this.chartData
            .filter((x) => x.Group === groupName)
            .map((x) => x.YValue)
            .reduce((a, b) => a + b);
    }

    public getTotalPercentageForGroup(groupName: string): number {
        return this.getTotalValueForGroup(groupName) / this.chartData.map((x) => x.YValue).reduce((a, b) => a + b);
    }

    ngOnChanges() {
        const vegaSpec = {
            $schema: "https://vega.github.io/schema/vega-lite/v5.json",
            width: "container",
            height: this.chartHeight,
            autosize: {
                type: "fit-x",
                resize: true,
            },
            config: {
                view: { stroke: null },
                axisY: {
                    labelFont: "Arial",
                    labelFontSize: 16,
                    labelFontWeight: "bold",
                    labelPadding: 10,
                    offset: 2,
                    ticks: false,
                    domainColor: "#dcdcdc",
                },
                axisX: {
                    labelFont: "Arial",
                    labelFontSize: 14,
                    labelFontWeight: "bold",
                    titleFontSize: 14,
                    titleAnchor: "start",
                    titleBaseline: "line-bottom",
                    titleAlign: "right",
                    titlePadding: 0,
                    titleX: -10,
                    titleOpacity: 0.5,
                    labelOpacity: 0.5,
                    ticks: false,
                    domain: false,
                },
                legend: {
                    disable: true,
                },
                scale: {
                    barBandPaddingInner: 0.2,
                },
            },
            data: {
                name: "table",
                values: this.chartData,
            },
            transform: [
                {
                    joinaggregate: [{ op: "sum", field: "YValue", as: "Total" }],
                    groupby: ["XValue"],
                },
                {
                    joinaggregate: [
                        {
                            op: "sum",
                            field: "HoverValue",
                            as: "TotalPercentage",
                        },
                    ],
                    groupby: ["XValue"],
                },
            ],
            encoding: {
                y: { field: "XValue", title: "", sort: { field: "SortOrder" } },
                x: {
                    field: "YValue",
                    title: "Total Miles",
                    type: "quantitative",
                    axis: { gridDash: { value: [8, 8] } },
                },
                color: {
                    sort: "ascending",
                    field: "Group",
                    title: "",
                    type: "nominal",
                    scale: {
                        domain: [...this.colorRange.keys()],
                        range: [...this.colorRange.values()],
                    },
                },
                order: { field: "SortOrder" },
                tooltip: [
                    { Field: "XValue", Title: "Species Name", Type: "ordinal" },
                    {
                        Field: "Group",
                        Title: "Distribution Type",
                        Type: "ordinal",
                    },
                    {
                        Field: "YValue",
                        Title: "Miles",
                        Type: "quantitative",
                        Format: ",.1f",
                    },
                    {
                        Field: "HoverValue",
                        Title: "% of Total Length",
                        Type: "quantitative",
                        Format: ".1%",
                    },
                ].map((x) => {
                    return {
                        field: x.Field,
                        title: x.Title,
                        type: x.Type,
                        format: x.Format,
                    };
                }),
            },
            layer: [
                {
                    mark: {
                        type: "bar",
                        height: 30,
                        cornerRadius: 6,
                        stroke: "#234F81",
                    },
                },
                {
                    mark: { type: "text", xOffset: 55, fontSize: 14 },
                    encoding: {
                        text: {
                            value: {
                                expr: 'format(datum.TotalPercentage, ".1%")',
                            },
                            type: "quantitative",
                        },
                        x: { field: "Total" },
                        color: { value: "gray" },
                    },
                },
            ],
        } as VisualizationSpec;

        vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: "svg" }).then((res) => {});
    }
}
