<div [loadingSpinner]="{ isLoading }" class="chartSection">
    <div [id]="chartID" style="width: 100%"></div>
    <hr />
    <div class="chartLegend">
        <div class="legend">
            <div *ngFor="let keyValuePair of colorRange | defaultOrderKeyvalue">
                <i [style.background]="keyValuePair.value"></i> <strong>{{ keyValuePair.key }}</strong>
                <span class="legendEntryExtraInfo"
                    >{{ getTotalValueForGroup(keyValuePair.key) | number: "1.0-1" }} acres ({{ getTotalPercentageForGroup(keyValuePair.key) | percent: "1.0-1" }})</span
                >
            </div>
        </div>
    </div>
</div>
