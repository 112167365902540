import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { UserService } from "src/app/shared/generated/api/user.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { forkJoin } from "rxjs";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { UserUpdateDto } from "src/app/shared/models/user-update-dto";
import { RoleDto } from "src/app/shared/generated/model/models";
import { routeParams } from "src/app/app.routes";
import { FormsModule } from "@angular/forms";
import { AlertDisplayComponent } from "src/app/shared/components/alert-display/alert-display.component";
import { PageHeaderComponent } from "src/app/shared/components/common/page-header/page-header.component";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "qanat-user-edit",
    templateUrl: "./user-edit.component.html",
    styleUrls: ["./user-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, PageHeaderComponent, AlertDisplayComponent, FormsModule, NgFor, RouterLink],
})
export class UserEditComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;

    public userID: number;
    public user: UserDto;
    public model: UserUpdateDto;
    public roles: Array<RoleDto>;
    public isLoadingSubmit: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;

            this.userID = this.route.snapshot.paramMap.get(routeParams.userID) ? parseInt(this.route.snapshot.paramMap.get(routeParams.userID)) : this.currentUser.UserID;

            forkJoin(this.userService.usersUserIDGet(this.userID), this.roleService.rolesGet()).subscribe(([user, roles]) => {
                this.user = user instanceof Array ? null : (user as UserDto);

                this.roles = roles.sort((a: RoleDto, b: RoleDto) => {
                    if (a.RoleDisplayName > b.RoleDisplayName) return 1;
                    if (a.RoleDisplayName < b.RoleDisplayName) return -1;
                    return 0;
                });

                this.model = new UserUpdateDto();
                this.model.RoleID = user.Role.RoleID;
                this.model.ReceiveSupportEmails = user.ReceiveSupportEmails;

                this.cdr.detectChanges();
            });
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    onSubmit(): void {
        this.isLoadingSubmit = true;

        this.userService.usersUserIDPut(this.userID, this.model).subscribe(
            (response) => {
                this.isLoadingSubmit = false;
                this.router.navigate(["../"], { relativeTo: this.route }).then((x) => {
                    this.alertService.pushAlert(new Alert("Successfully updated user profile.", AlertContext.Success));
                });
            },
            (error) => {
                this.isLoadingSubmit = false;
                this.cdr.detectChanges();
            }
        );
    }

    checkReceiveSupportEmails(): void {
        if (this.model.RoleID != 1) {
            this.model.ReceiveSupportEmails = false;
        }
    }
}
