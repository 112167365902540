import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class WfsService {
    constructor(private http: HttpClient) {}

    public getPIDByCoordinate(longitude: number, latitude: number): Observable<string> {
        const url: string = `${environment.geoserverMapServiceUrl}/wms`;
        const cqlFilter = `intersects(Geometry4326, POINT(${latitude} ${longitude}))`;
        return this.http.get(url, {
            params: {
                service: "WFS",
                version: "2.0.0",
                request: "GetPropertyValue",
                SrsName: "EPSG:4326",
                typeName: "Riparis:AllReaches",
                count: "1",
                valueReference: "PermanentIdentifier",
                cql_filter: cqlFilter,
            },
            responseType: "text",
        });
    }

    public getWriaIDByCoordinate(longitude: number, latitude: number): Observable<string> {
        const url: string = `${environment.geoserverMapServiceUrl}/wms`;
        const cqlFilter = `intersects(Geometry4326, POINT(${latitude} ${longitude}))`;
        return this.http.get(url, {
            params: {
                service: "WFS",
                version: "2.0.0",
                request: "GetPropertyValue",
                SrsName: "EPSG:4326",
                typeName: "Riparis:Wrias",
                count: "1",
                valueReference: "WaterResourceInventoryAreaID",
                cql_filter: cqlFilter,
            },
            responseType: "text",
        });
    }

    public getCountyIDByCoordinate(longitude: number, latitude: number): Observable<string> {
        const url: string = `${environment.geoserverMapServiceUrl}/wms`;
        const cqlFilter = `intersects(Geometry4326, POINT(${latitude} ${longitude}))`;
        return this.http.get(url, {
            params: {
                service: "WFS",
                version: "2.0.0",
                request: "GetPropertyValue",
                SrsName: "EPSG:4326",
                typeName: "Riparis:Counties",
                count: "1",
                valueReference: "CountyID",
                cql_filter: cqlFilter,
            },
            responseType: "text",
        });
    }

    public getGeoserverWFSLayer(layer: string): Observable<number[]> {
        const url: string = `${environment.geoserverMapServiceUrl}/ows`;
        const wfsParams = new HttpParams()
            .set("responseType", "json")
            .set("service", "wfs")
            .set("version", "2.0.0")
            .set("request", "GetFeature")
            .set("SrsName", "EPSG:4326")
            .set("typeName", layer)
            .set("outputFormat", "application/json");

        return this.http.post(url, wfsParams).pipe(
            map((rawData: any) => {
                return rawData.features;
            })
        );
    }

    public getGeoserverWFSLayerWithCQLFilter(layer: string, cqlFilter: string): Observable<number[]> {
        const url: string = `${environment.geoserverMapServiceUrl}/ows`;
        const wfsParams = new HttpParams()
            .set("responseType", "json")
            .set("service", "wfs")
            .set("version", "2.0.0")
            .set("request", "GetFeature")
            .set("SrsName", "EPSG:4326")
            .set("typeName", layer)
            .set("outputFormat", "application/json")
            .set("cql_filter", cqlFilter);

        return this.http.post(url, wfsParams).pipe(
            map((rawData: any) => {
                return rawData.features;
            })
        );
    }
}
