export class NetworkStatisticsDto {
    ReachCount?: number;
    NetworkDepth?: number;
    TotalAcres?: number;
    AllLandCoverAcres?: number;
    EOWAcres?: number;
    RMZAcres?: number;
    FloodAcres?: number;
    EOWVegetatedAcres?: number;
    EOWTreeCoverAcres?: number;
    EOWShrubAcres?: number;
    EOWHerbaceousAcres?: number;
    EOWWaterAcres?: number;
    EOWNoVegetationDataAcres?: number;
    EOWNonVegetatedAcres?: number;
    RMZVegetatedAcres?: number;
    RMZShrubAcres?: number;
    RMZTreeCoverAcres?: number;
    RMZHerbaceousAcres?: number;
    RMZWaterAcres?: number;
    RMZNoVegetationDataAcres?: number;
    RMZNonVegetatedAcres?: number;
    FloodVegetatedAcres?: number;
    FloodTreeCoverAcres?: number;
    FloodShrubAcres?: number;
    FloodHerbaceousAcres?: number;
    FloodWaterAcres?: number;
    FloodNoVegetationDataAcres?: number;
    FloodNonVegetatedAcres?: number;
    MilesOfStream?: number;
    MilesOfTemperatureImpairedStream?: number;
    MilesOfSalmonBearingStream?: number;
    FishPassageBarriersCount?: number;
    ImpassableFishPassageBarriersCount?: number;
    SalmonidsSpeciesPresent: Array<string>;
    ChinookSalmonDocumentedMiles?: number;
    ChinookSalmonPresumedMiles?: number;
    ChinookSalmonGradientAccessibleMiles?: number;
    ChinookSalmonMiles?: number;
    ChumSalmonDocumentedMiles?: number;
    ChumSalmonPresumedMiles?: number;
    ChumSalmonGradientAccessibleMiles?: number;
    ChumSalmonMiles?: number;
    CohoSalmonDocumentedMiles?: number;
    CohoSalmonPresumedMiles?: number;
    CohoSalmonGradientAccessibleMiles?: number;
    CohoSalmonMiles?: number;
    KokaneeSalmonDocumentedMiles?: number;
    KokaneeSalmonPresumedMiles?: number;
    KokaneeSalmonGradientAccessibleMiles?: number;
    KokaneeSalmonMiles?: number;
    PinkSalmonDocumentedMiles?: number;
    PinkSalmonPresumedMiles?: number;
    PinkSalmonGradientAccessibleMiles?: number;
    PinkSalmonMiles?: number;
    SockeyeSalmonDocumentedMiles?: number;
    SockeyeSalmonPresumedMiles?: number;
    SockeyeSalmonGradientAccessibleMiles?: number;
    SockeyeSalmonMiles?: number;
    SteelheadTroutDocumentedMiles?: number;
    SteelheadTroutPresumedMiles?: number;
    SteelheadTroutGradientAccessibleMiles?: number;
    SteelheadTroutMiles?: number;
    AgricultureAcres?: number;
    AgricultureChapter83Acres?: number;
    AgricultureNotLaw81Acres?: number;
    CommercialAcres?: number;
    ForestryForestLandAcres?: number;
    ForestTimberlandDataAcres?: number;
    OpenSpaceAcres?: number;
    ResidentialAcres?: number;
    ResourceAcres?: number;
    TransportationAcres?: number;
    UndevelopedLandAcres?: number;
    UndevelopedLands91Acres?: number;
    AllLandUseAcres?: number;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
