<section class="content-section">
    <div
        class="grid-12"
        [loadingSpinner]="{
            isLoading: isLoadingReach,
            loadingHeight: 100,
        }">
        <div class="g-col-4 summary-statistics">
            <ng-template #reachNotFound> Reach {{ this.permanentIdentifier }} not found! </ng-template>

            <ng-container *ngIf="reach; else reachNotFound">
                <div class="summary-statistics-wrapper overview">
                    <div class="summary-statistics-header">
                        <h4>
                            <icon icon="Reach"></icon> Reach
                            <span class="reach-title">
                                {{ reach.StreamName }}
                            </span>
                        </h4>
                    </div>
                    <div class="summary-statistics-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="rmz-header">
                                            <field-definition [fieldDefinitionType]="'RMZ'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="eow-header">
                                            <field-definition [fieldDefinitionType]="'EOW'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="flood-header">
                                            <field-definition [fieldDefinitionType]="'Floodplain'" [labelOverride]="'Flood'" [inline]="false"></field-definition>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'Acres'" [labelOverride]="'Acres'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ reach.RMZAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ reach.EOWAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ reach.FloodAcres | number: "1.1-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentTreeCover'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ reach.RMZTreeCoverAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ reach.EOWTreeCoverAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ reach.FloodTreeCoverAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentVegetated'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ reach.RMZVegetatedAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ reach.EOWVegetatedAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ reach.FloodVegetatedAcres / reach.ReachAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="reach-stat__wrapper">
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Ruler"></icon>
                                    <field-definition [fieldDefinitionType]="'MilesOfStream'" [inline]="false"></field-definition>
                                    <strong>
                                        {{ reach.StreamMiles | number: "1.1-1" }}
                                    </strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Thermometer"></icon>
                                    <field-definition [fieldDefinitionType]="'TemperatureImpairedWaterbodies'" [inline]="false"></field-definition>
                                    <strong>
                                        {{ reach.TemperatureImpairments }}
                                    </strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Barrier"></icon>
                                    <field-definition [fieldDefinitionType]="'FishPassageBarriers'" [labelOverride]="'Fish Passage Barriers'" [inline]="false"></field-definition>
                                    <strong>
                                        {{ reach.FishPassageBarriersCount | number: "1.0-0" }}
                                        (
                                        <div class="ban">
                                            {{ reach.ImpassableFishPassageBarriersCount | number: "1.0-0" }}
                                            <icon icon="Ban"></icon>
                                        </div>
                                        )
                                    </strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label toggleable" [expandCollapse]="overviewSalmonidBodyLocation" [startOpen]="false">
                                    <icon icon="Fish"></icon>
                                    <field-definition
                                        [fieldDefinitionType]="'SalmonidsSpeciesPresent'"
                                        [labelOverride]="'Salmonids Species Present:'"
                                        [inline]="false"></field-definition>

                                    <strong>{{ reachNetworkResult.SalmonidsSpeciesPresent.length }}</strong>
                                    <span class="toggle" *ngIf="reachNetworkResult.SalmonidsSpeciesPresent.length > 0">
                                        <icon icon="AngleDown"></icon>
                                    </span>
                                </div>

                                <ul class="reach-stat__list" #overviewSalmonidBodyLocation>
                                    <li *ngFor="let salmonSpecies of reachNetworkResult.SalmonidsSpeciesPresent">
                                        {{ salmonSpecies }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary-statistics-wrapper upstream mt-4">
                    <div class="summary-statistics-header">
                        <h4><icon icon="Reach"></icon> Upstream Statistics</h4>
                    </div>
                    <ng-template #noUpstreamStatistics> There are no reaches upstream. </ng-template>
                    <div class="summary-statistics-body" *ngIf="upstreamNetworkStatistics?.ReachCount > 0; else noUpstreamStatistics">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="rmz-header">
                                            <field-definition [fieldDefinitionType]="'RMZ'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="eow-header">
                                            <field-definition [fieldDefinitionType]="'EOW'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="flood-header">
                                            <field-definition [fieldDefinitionType]="'Floodplain'" [labelOverride]="'Flood'" [inline]="false"></field-definition>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'Acres'" [labelOverride]="'Acres'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.RMZAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.EOWAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.FloodAcres | number: "1.1-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentTreeCover'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.RMZTreeCoverAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.EOWTreeCoverAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.FloodTreeCoverAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentVegetated'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.RMZVegetatedAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.EOWVegetatedAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ upstreamNetworkStatistics.FloodVegetatedAcres / upstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="reach-stat__wrapper">
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Reach"></icon>
                                    <field-definition [fieldDefinitionType]="'ReachCount'" [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.ReachCount | number: "1.0-0" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Network"></icon>
                                    <field-definition [fieldDefinitionType]="'NetworkDepth'" [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.NetworkDepth | number: "1.0-0" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Ruler"></icon>
                                    <field-definition [fieldDefinitionType]="'MilesOfStream'" [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.MilesOfStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Thermometer"></icon>
                                    <field-definition
                                        [fieldDefinitionType]="'TemperatureImpairedWaterbodies'"
                                        [labelOverride]="'Miles of Temperature Impaired Stream'"
                                        [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.MilesOfTemperatureImpairedStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Fish"></icon>
                                    <field-definition [fieldDefinitionType]="'MilesOfSalmonBearingStream'" [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.MilesOfSalmonBearingStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Barrier"></icon>
                                    <field-definition [fieldDefinitionType]="'FishPassageBarriers'" [labelOverride]="'Fish Passage Barriers'" [inline]="false"></field-definition>
                                    <strong>
                                        {{ upstreamNetworkStatistics.FishPassageBarriersCount | number: "1.0-0" }}
                                        (
                                        <div class="ban">
                                            {{ upstreamNetworkStatistics.ImpassableFishPassageBarriersCount | number: "1.0-0" }}
                                            <icon icon="Ban"></icon>
                                        </div>
                                        )
                                    </strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label toggleable" [expandCollapse]="upstreamSalmonidBodyLocation" [startOpen]="false">
                                    <icon icon="Fish"></icon>
                                    <field-definition
                                        [fieldDefinitionType]="'SalmonidsSpeciesPresent'"
                                        [labelOverride]="'Salmonids Species Present:'"
                                        [inline]="false"></field-definition>
                                    <strong>{{ upstreamNetworkStatistics.SalmonidsSpeciesPresent.length }}</strong>
                                    <span class="toggle" *ngIf="upstreamNetworkStatistics.SalmonidsSpeciesPresent.length > 0">
                                        <icon icon="AngleDown"></icon>
                                    </span>
                                </div>

                                <ul class="reach-stat__list" #upstreamSalmonidBodyLocation>
                                    <li *ngIf="upstreamNetworkStatistics.CohoSalmonMiles > 0">
                                        Coho Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.CohoSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.CohoSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.ChinookSalmonMiles > 0">
                                        Chinook Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.ChinookSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.ChinookSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.PinkSalmonMiles > 0">
                                        Pink Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.PinkSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.PinkSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.ChumSalmonMiles > 0">
                                        Chum Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.ChumSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.ChumSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.SteelheadTroutMiles > 0">
                                        Steelhead Trout
                                        <strong>
                                            {{ upstreamNetworkStatistics.SteelheadTroutMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.SteelheadTroutMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.SockeyeSalmonMiles > 0">
                                        Sockeye Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.SockeyeSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.SockeyeSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="upstreamNetworkStatistics.KokaneeSalmonMiles > 0">
                                        Kokanee Salmon
                                        <strong>
                                            {{ upstreamNetworkStatistics.KokaneeSalmonMiles | number: "1.0-1" }}
                                            miles ({{ upstreamNetworkStatistics.KokaneeSalmonMiles / upstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary-statistics-wrapper downstream mt-4">
                    <div class="summary-statistics-header">
                        <h4><icon icon="Reach"></icon> Downstream Statistics</h4>
                    </div>
                    <ng-template #noDownstreamStatistics> There are no reaches downstream. </ng-template>
                    <div class="summary-statistics-body" *ngIf="downstreamNetworkStatistics?.ReachCount > 0; else noDownstreamStatistics">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="rmz-header">
                                            <field-definition [fieldDefinitionType]="'RMZ'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="eow-header">
                                            <field-definition [fieldDefinitionType]="'EOW'" [inline]="false"></field-definition>
                                        </th>
                                        <th class="flood-header">
                                            <field-definition [fieldDefinitionType]="'Floodplain'" [labelOverride]="'Flood'" [inline]="false"></field-definition>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'Acres'" [labelOverride]="'Acres'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.RMZAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.EOWAcres | number: "1.1-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.FloodAcres | number: "1.1-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentTreeCover'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.RMZTreeCoverAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.EOWTreeCoverAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.FloodTreeCoverAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <field-definition [fieldDefinitionType]="'PercentVegetated'" [inline]="false"></field-definition>
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.RMZVegetatedAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.EOWVegetatedAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                        <td>
                                            {{ downstreamNetworkStatistics.FloodVegetatedAcres / downstreamNetworkStatistics.TotalAcres | percent: "1.0-1" }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="reach-stat__wrapper">
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Reach"></icon>
                                    <field-definition [fieldDefinitionType]="'ReachCount'" [inline]="false"></field-definition>

                                    <strong>{{ downstreamNetworkStatistics.ReachCount | number: "1.0-0" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Network"></icon>
                                    <field-definition [fieldDefinitionType]="'NetworkDepth'" [inline]="false"></field-definition>
                                    <strong>{{ downstreamNetworkStatistics.NetworkDepth | number: "1.0-0" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Ruler"></icon>
                                    <field-definition [fieldDefinitionType]="'MilesOfStream'" [inline]="false"></field-definition>
                                    <strong>{{ downstreamNetworkStatistics.MilesOfStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Thermometer"></icon>
                                    <field-definition
                                        [fieldDefinitionType]="'TemperatureImpairedWaterbodies'"
                                        [labelOverride]="'Miles of Temperature Impaired Stream'"
                                        [inline]="false"></field-definition>
                                    <strong>{{ downstreamNetworkStatistics.MilesOfTemperatureImpairedStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Fish"></icon>
                                    <field-definition [fieldDefinitionType]="'MilesOfSalmonBearingStream'" [inline]="false"></field-definition>
                                    <strong>{{ downstreamNetworkStatistics.MilesOfSalmonBearingStream | number: "1.1-1" }}</strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label">
                                    <icon icon="Barrier"></icon>
                                    <field-definition [fieldDefinitionType]="'FishPassageBarriers'" [labelOverride]="'Fish Passage Barriers'" [inline]="false"></field-definition>
                                    <strong>
                                        {{ downstreamNetworkStatistics.FishPassageBarriersCount | number: "1.0-0" }}
                                        (
                                        <div class="ban">
                                            {{ downstreamNetworkStatistics.ImpassableFishPassageBarriersCount | number: "1.0-0" }}
                                            <icon icon="Ban"></icon>
                                        </div>
                                        )
                                    </strong>
                                </div>
                            </div>
                            <div class="reach-stat">
                                <div class="reach-stat__label toggleable" [expandCollapse]="downstreamSalmonidBodyLocation" [startOpen]="false">
                                    <icon icon="Fish"></icon>
                                    <field-definition
                                        [fieldDefinitionType]="'SalmonidsSpeciesPresent'"
                                        [labelOverride]="'Salmonids Species Present:'"
                                        [inline]="false"></field-definition>
                                    <strong>{{ downstreamNetworkStatistics.SalmonidsSpeciesPresent.length }}</strong>
                                    <span class="toggle" *ngIf="downstreamNetworkStatistics.SalmonidsSpeciesPresent.length > 0">
                                        <icon icon="AngleDown"></icon>
                                    </span>
                                </div>

                                <ul class="reach-stat__list" #downstreamSalmonidBodyLocation>
                                    <li *ngIf="downstreamNetworkStatistics.CohoSalmonMiles > 0">
                                        Coho Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.CohoSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.CohoSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.ChinookSalmonMiles > 0">
                                        Chinook Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.ChinookSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.ChinookSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.PinkSalmonMiles > 0">
                                        Pink Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.PinkSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.PinkSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.ChumSalmonMiles > 0">
                                        Chum Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.ChumSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.ChumSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.SteelheadTroutMiles > 0">
                                        Steelhead Trout
                                        <strong>
                                            {{ downstreamNetworkStatistics.SteelheadTroutMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.SteelheadTroutMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.SockeyeSalmonMiles > 0">
                                        Sockeye Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.SockeyeSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.SockeyeSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                    <li *ngIf="downstreamNetworkStatistics.KokaneeSalmonMiles > 0">
                                        Kokanee Salmon
                                        <strong>
                                            {{ downstreamNetworkStatistics.KokaneeSalmonMiles | number: "1.0-1" }}
                                            miles ({{ downstreamNetworkStatistics.KokaneeSalmonMiles / downstreamNetworkStatistics.MilesOfStream | percent: "1.0-1" }})
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="g-col-8">
            <ng-container *ngIf="reach">
                <div class="page-header">
                    <h1 class="page-title page-title__watershed">
                        <span class="icon-wrapper">
                            <icon icon="MapOpen"></icon>
                        </span>
                        {{ reach.PermanentIdentifier }}
                        <span class="wria-id">
                            <a routerLink="/wrias/{{ reach.WaterResourceInventoryAreaID }}">
                                (WRIA
                                {{ reach.WaterResourceInventoryAreaID }})
                            </a>
                        </span>
                    </h1>
                </div>

                <btn-group-radio-input [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab" [showIcons]="true"></btn-group-radio-input>
            </ng-container>

            <riparis-map (onMapLoad)="handleMapReady($event)" mapHeight="800px" [hidden]="activeTab !== 'Map'"> </riparis-map>

            <ng-template #noReachesStatistics> There are no reaches {{ currentChartOption }}. </ng-template>

            <div style="height: 800px" [hidden]="activeTab !== 'LandCover'">
                <div class="mt-4">
                    <div style="display: flex">
                        <h3 class="chartTitle">Land Cover by Type</h3>
                        <i style="margin-left: auto"></i>
                        <button-group>
                            <button *ngFor="let chartOption of chartOptions" (click)="changeChartOption(chartOption)" [class.active]="chartOption === currentChartOption">
                                {{ chartOption }}
                            </button>
                        </button-group>
                    </div>
                    <hr />
                    <land-cover-bar-chart *ngIf="landCoverChartData?.length > 0; else noReachesStatistics" [chartData]="landCoverChartData"></land-cover-bar-chart>
                </div>
            </div>

            <div style="height: 800px" [hidden]="activeTab !== 'Hydrology'">
                <div class="mt-4">
                    <div style="display: flex">
                        <h3 class="chartTitle">Area by Reach Zone</h3>
                        <i style="margin-left: auto"></i>
                        <button-group>
                            <button *ngFor="let chartOption of chartOptions" (click)="changeChartOption(chartOption)" [class.active]="chartOption === currentChartOption">
                                {{ chartOption }}
                            </button>
                        </button-group>
                    </div>
                    <hr />
                    <zone-area-radial-chart *ngIf="zoneAreaChartData?.length > 0; else noReachesStatistics" [chartData]="zoneAreaChartData"></zone-area-radial-chart>
                </div>
            </div>

            <div style="height: 800px" [hidden]="activeTab !== 'Salmon'">
                <div class="mt-4">
                    <div style="display: flex">
                        <h3 class="chartTitle">Salmon Distribution by Species</h3>
                        <i style="margin-left: auto"></i>
                        <button-group>
                            <button *ngFor="let chartOption of chartOptions" (click)="changeChartOption(chartOption)" [class.active]="chartOption === currentChartOption">
                                {{ chartOption }}
                            </button>
                        </button-group>
                    </div>
                    <hr />
                    <salmon-distribution-bar-chart
                        *ngIf="salmonDistributionChartData?.length > 0; else noReachesStatistics"
                        [chartData]="salmonDistributionChartData"></salmon-distribution-bar-chart>
                </div>
            </div>

            <div style="height: 800px" [hidden]="activeTab !== 'Administrative'">
                <div class="mt-4">
                    <div style="display: flex">
                        <h3 class="chartTitle">Area by Land Use</h3>
                        <i style="margin-left: auto"></i>
                        <button-group>
                            <button *ngFor="let chartOption of chartOptions" (click)="changeChartOption(chartOption)" [class.active]="chartOption === currentChartOption">
                                {{ chartOption }}
                            </button>
                        </button-group>
                    </div>
                    <hr />
                    <land-use-waffle-chart *ngIf="landUseChartData?.length > 0; else noReachesStatistics" [chartData]="landUseChartData"></land-use-waffle-chart>
                </div>
            </div>

            <div style="height: 800px" [hidden]="activeTab !== 'Reach'">
                <riparis-grid
                    height="700px"
                    [rowData]="reachNetworkResults"
                    [columnDefs]="columnDefs"
                    downloadDisplayName="reaches-shown"
                    [pagination]="true"
                    [sizeColumnsToFitGrid]="true"
                    (gridReady)="onGridReady($event)"
                    rowSelection="single"
                    (selectionChanged)="onSelectionChanged($event)">
                </riparis-grid>
            </div>
        </div>
    </div>
    <div class="grid-12 mt-4" *ngIf="reach"></div>

    <section class="alert-container grid-12">
        <app-alert-display></app-alert-display>
    </section>
</section>
