import { Component, Input } from "@angular/core";
import { default as vegaEmbed, VisualizationSpec } from "vega-embed";
import { LoadingDirective } from "../../../directives/loading.directive";
import { VegaChartField } from "../VegaChartField";
import { ChartData } from "../ChartData";
import { VegaColorScale } from "../VegaColorScale";
import { KeyValuePipe, NgIf, NgForOf, PercentPipe, DecimalPipe } from "@angular/common";
import { SumPipe } from "src/app/shared/pipes/sum.pipe";

@Component({
    selector: "zone-area-radial-chart",
    standalone: true,
    imports: [LoadingDirective, KeyValuePipe, NgIf, NgForOf, PercentPipe, DecimalPipe, SumPipe],
    templateUrl: "./zone-area-radial-chart.component.html",
    styleUrl: "./zone-area-radial-chart.component.scss",
})
export class ZoneAreaRadialChartComponent {
    @Input() xAxis: VegaChartField;
    @Input() yAxis: VegaChartField;
    @Input() tooltips: VegaChartField[];
    @Input() chartData: ChartData[];
    @Input() chartHeight: number = 350;
    @Input() colorScale: VegaColorScale;
    @Input() isLoading: boolean = false;

    public chartID: string = "zoneAreaRadialChart";
    public colorRange: Map<string, string> = new Map([
        ["Riparian Management Zone", "#CDF57A"],
        ["Extent of Observed Water", "#9EBBD7"],
        ["Floodplain", "#9ED7C2"],
    ]);

    ngOnChanges() {
        const vegaSpec = {
            $schema: "https://vega.github.io/schema/vega-lite/v5.json",
            width: this.chartHeight,
            height: this.chartHeight,
            autosize: {
                type: "fit",
                resize: true,
            },
            config: {
                legend: {
                    disable: true,
                },
            },
            data: {
                name: "table",
                values: this.chartData,
            },
            encoding: {
                theta: { field: "YValue", type: "quantitative", stack: true },
                radius: { field: "YValue", scale: { type: "sqrt", zero: true, rangeMin: 100 } },
                color: {
                    sort: "ascending",
                    field: "XValue",
                    title: "",
                    type: "nominal",
                    scale: {
                        domain: [...this.colorRange.keys()],
                        range: [...this.colorRange.values()],
                    },
                },
                tooltip: [
                    { Field: "XValue", Title: "Zone", Type: "ordinal" },
                    { Field: "YValue", Title: "Acres", Type: "quantitative", Format: ",.1f" },
                    { Field: "HoverValue", Title: "% of Total Area", Type: "quantitative", Format: ".1%" },
                ].map((x) => {
                    return { field: x.Field, title: x.Title, type: x.Type, format: x.Format };
                }),
            },
            layer: [
                {
                    mark: { type: "arc", innerRadius: 40, stroke: "#fff" },
                },
                {
                    mark: { type: "text", radiusOffset: 30, fontSize: 14, fill: "#000" },
                    encoding: {
                        text: { field: "HoverValue", type: "quantitative", format: ".1%" },
                    },
                },
            ],
        } as VisualizationSpec;
        console.log(vegaSpec);
        vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: "svg" }).then((res) => {});
    }
}
