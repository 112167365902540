<div class="page-header" [ngClass]="templateRight ? 'flex-between no-wrap' : ''">
    <div>
        <ng-container *ngIf="templateAbove" [ngTemplateOutlet]="templateAbove"></ng-container>
        <h3 *ngIf="preTitle" class="page-tab">{{ preTitle }}</h3>
        <h1 class="page-title">
            <icon class="page-header-icon" *ngIf="icon" [icon]="icon"></icon>
            {{ pageTitleDisplay }}
            <ng-container *ngIf="templateTitleAppend" [ngTemplateOutlet]="templateTitleAppend"></ng-container>
        </h1>
        <div class="copy copy-2" *ngIf="customRichTextTypeID">
            <custom-rich-text [customRichTextTypeID]="customRichTextTypeID" [geographyID]="customRichTextTypeGeographyID"></custom-rich-text>
        </div>
        <ng-content></ng-content>
    </div>
    <div *ngIf="templateRight">
        <ng-container [ngTemplateOutlet]="templateRight"></ng-container>
    </div>
</div>
