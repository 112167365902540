import { Component, Input } from "@angular/core";
import { default as vegaEmbed, VisualizationSpec } from "vega-embed";
import { LoadingDirective } from "../../../directives/loading.directive";
import { ChartData } from "../ChartData";
import { DecimalPipe, NgForOf, NgIf, PercentPipe } from "@angular/common";
import { SumPipe } from "src/app/shared/pipes/sum.pipe";
import { DefaultOrderKeyvaluePipe } from "src/app/shared/pipes/default-order-key-value.pipe";

@Component({
    selector: "land-cover-bar-chart",
    standalone: true,
    imports: [LoadingDirective, DefaultOrderKeyvaluePipe, NgIf, NgForOf, PercentPipe, DecimalPipe, SumPipe],
    templateUrl: "./land-cover-bar-chart.component.html",
    styleUrl: "./land-cover-bar-chart.component.scss",
})
export class LandCoverBarChartComponent {
    @Input() chartData: ChartData[];
    @Input() chartHeight: number = 400;
    @Input() isLoading: boolean = false;

    public chartID: string = "landCoverBarChart";
    public colorRange: Map<string, string> = new Map([
        ["Riparian Management Zone", "#CDF57A"],
        ["Extent of Observed Water", "#9EBBD7"],
        ["Floodplain", "#9ED7C2"],
    ]);

    public getTotalValueForGroup(groupName: string): number {
        return this.chartData
            .filter((x) => x.Group === groupName)
            .map((x) => x.YValue)
            .reduce((a, b) => a + b);
    }

    public getTotalPercentageForGroup(groupName: string): number {
        return this.getTotalValueForGroup(groupName) / this.chartData.map((x) => x.YValue).reduce((a, b) => a + b);
    }

    ngOnChanges() {
        const vegaSpec = {
            $schema: "https://vega.github.io/schema/vega-lite/v5.json",
            width: "container",
            height: this.chartHeight,
            autosize: {
                type: "fit-x",
                resize: true,
            },
            config: {
                view: { stroke: null },
                axisY: {
                    labelFont: "Arial",
                    labelFontSize: 16,
                    labelFontWeight: "bold",
                    labelPadding: 10,
                    offset: 2,
                    ticks: false,
                    domainColor: "#dcdcdc",
                },
                axisX: {
                    labelFont: "Arial",
                    labelFontSize: 14,
                    labelFontWeight: "bold",
                    titleFontSize: 14,
                    titleAnchor: "start",
                    titleBaseline: "line-bottom",
                    titleAlign: "right",
                    titlePadding: 0,
                    titleX: -10,
                    titleOpacity: 0.5,
                    labelOpacity: 0.5,
                    ticks: false,
                    domain: false,
                },
                legend: {
                    disable: true,
                },
                scale: {
                    barBandPaddingInner: 0.2,
                },
            },
            data: {
                name: "table",
                values: this.chartData,
            },
            transform: [
                {
                    joinaggregate: [{ op: "sum", field: "YValue", as: "Total" }],
                    groupby: ["XValue"],
                },
                {
                    joinaggregate: [{ op: "sum", field: "HoverValue", as: "TotalPercentage" }],
                    groupby: ["XValue"],
                },
            ],
            encoding: {
                y: { field: "XValue", title: "", sort: { field: "SortOrder" } },
                x: { field: "YValue", title: "Total Acres", type: "quantitative", axis: { gridDash: { value: [8, 8] } } },
                color: {
                    sort: "ascending",
                    field: "Group",
                    title: "",
                    type: "nominal",
                    scale: {
                        domain: [...this.colorRange.keys()],
                        range: [...this.colorRange.values()],
                    },
                },
                order: { field: "SortOrder" },
                tooltip: [
                    { Field: "XValue", Title: "Land Cover", Type: "ordinal" },
                    { Field: "Group", Title: "Zone", Type: "ordinal" },
                    { Field: "YValue", Title: "Acres", Type: "quantitative", Format: ",.1f" },
                    { Field: "HoverValue", Title: "% Acres", Type: "quantitative", Format: ".1%" },
                ].map((x) => {
                    return { field: x.Field, title: x.Title, type: x.Type, format: x.Format };
                }),
            },
            layer: [
                {
                    mark: { type: "bar", height: 30, cornerRadius: 6, stroke: "#CDF57A" },
                },
                {
                    mark: { type: "text", xOffset: 55, fontSize: 14 },
                    encoding: {
                        text: { value: { expr: 'format(datum.TotalPercentage, ".1%")' }, type: "quantitative" },
                        x: { field: "Total" },
                        color: { value: "gray" },
                    },
                },
            ],
        } as VisualizationSpec;

        vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: "svg" }).then((res) => {});
    }
}
