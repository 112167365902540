<reach-explorer
    *ngIf="currentReachExplorerEntity"
    [currentReachExplorerEntity]="currentReachExplorerEntity"
    [userFilterObjectType]="userFilterObjectType"
    [userID]="currentUser.UserID"
    [reachExplorerEntities]="reachExplorerEntities"></reach-explorer>

<section class="alert-container grid-12">
    <app-alert-display></app-alert-display>
</section>
