import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Observable, forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { RouterModule } from "@angular/router";
import { WaterResourceInventoryAreaService } from "src/app/shared/generated/api/water-resource-inventory-area.service";
import { WaterResourceInventoryAreaDto } from "src/app/shared/generated/model/models";
import { WaterResourceInventoryAreaTitleComponent } from "../../water-resource-inventory-area/water-resource-inventory-area-title/water-resource-inventory-area-title.component";
import { LoadingDirective } from "src/app/shared/directives/loading.directive";

@Component({
    selector: "water-resource-inventory-area-popup",
    standalone: true,
    imports: [CommonModule, WaterResourceInventoryAreaTitleComponent, RouterModule, LoadingDirective],
    templateUrl: "./water-resource-inventory-area-popup.component.html",
    styleUrls: ["./water-resource-inventory-area-popup.component.scss"],
})
export class WaterResourceInventoryAreaPopupComponent implements OnInit, OnDestroy {
    @Input("water-resource-inventory-area-id") waterResourceInventoryAreaID: number;
    public isLoading: boolean = true;

    public data$: Observable<any>;
    public waterResourceInventoryArea: WaterResourceInventoryAreaDto;

    constructor(
        private waterResourceInventoryAreaService: WaterResourceInventoryAreaService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnDestroy(): void {}

    ngOnInit(): void {
        this.data$ = forkJoin([this.waterResourceInventoryAreaService.wriasWaterResourceInventoryAreaIDGet(this.waterResourceInventoryAreaID)]).pipe(
            tap((x) => {
                this.waterResourceInventoryArea = x[0];
                this.isLoading = false;
            })
        );
    }
}
