<ng-container *ngIf="userAndCurrentUser$ | async as userAndCurrentUser">
    <div class="full-height-wrapper">
        <page-header [pageTitle]="user?.FullName"> </page-header>

        <div class="page-body grid-12">
            <app-alert-display></app-alert-display>
            <div class="card g-col-6">
                <div class="card-header flex-between">
                    <h3 class="card-title">User Information</h3>
                    <div class="card-actions">
                        <button *ngIf="!isCurrentUser && canImpersonate()" class="btn btn-secondary-outline" (click)="impersonateUser(user?.UserID)">
                            <span class="fas fa-user"></span> Impersonate
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <dl class="kv-pair">
                        <dt class="key">Full Name</dt>
                        <dd class="value">
                            <div>{{ user?.FullName }}</div>
                            <em *ngIf="!user?.FullName" class="text-muted">Not Available</em>
                        </dd>

                        <dt class="key">Email</dt>
                        <dd class="value">
                            <div>{{ user?.Email }}</div>
                            <em *ngIf="!user?.Email" class="text-muted">Not Available</em>
                        </dd>
                    </dl>
                </div>
            </div>

            <div class="user-permissions card g-col-6">
                <div class="card-header flex-between">
                    <h3 class="card-title">User Permissions</h3>
                </div>

                <div class="card-body">
                    <div class="platform-permissions">
                        <h4 class="module-title permissions-title underline">Platform</h4>

                        <div class="copy copy-2">
                            <ul>
                                <li>
                                    <strong>Role:</strong>
                                    {{ user?.Role?.RoleDisplayName }}
                                    <em *ngIf="!user?.Role?.RoleDisplayName" class="text-muted">Not Available</em>
                                    <a *ngIf="canUpdateUser()" routerLink="edit"><em>(Edit)</em></a>
                                </li>

                                <li *ngIf="userIsAdmin">
                                    <strong>Receives System Communications:</strong>
                                    <span *ngIf="userIsAdmin">
                                        {{ user?.ReceiveSupportEmails === true ? "Yes" : "No" }}
                                        <em *ngIf="!user" class="text-muted">Not Available</em>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
