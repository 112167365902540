import { Observable, race, ReplaySubject } from "rxjs";
import { concatMap, filter, first, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { ImpersonationService } from "src/app/shared/generated/api/impersonation.service";
import { Inject, Injectable } from "@angular/core";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { b2cPolicies } from "src/app/auth.config";
import { UserClaimsService } from "src/app/shared/generated/api/user-claims.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private currentUser: UserDto;
    private claimsUser: any;

    private _currentUserSetSubject = new ReplaySubject<UserDto>(1);
    public currentUserSetObservable = this._currentUserSetSubject.asObservable();

    constructor(
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private userClaimsService: UserClaimsService,
        private impersonationService: ImpersonationService,
        private alertService: AlertService
    ) {
        // Required for MSAL to work: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6719
        this.authService.handleRedirectObservable().subscribe();
        this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
            const payload = result.payload as AuthenticationResult;

            switch (payload.authority) {
                default:
                    this.authService.instance.setActiveAccount(payload.account);
                    this.claimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
                    this.postUser();
                    break;
            }
        });

        this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
            this.checkAndSetActiveAccount();
            this.updateActiveAccount();
        });
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    updateActiveAccount(forceGet: boolean = false) {
        const newClaimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
        if (newClaimsUser && (!this.claimsUser || newClaimsUser.sub != this.claimsUser.sub || forceGet)) {
            this.claimsUser = newClaimsUser;
            this.getUser(this.claimsUser);
        }
    }

    private getUser(claims: any) {
        const globalID = claims.sub;

        this.userClaimsService.userClaimsGlobalIDGet(globalID).subscribe(
            (result) => {
                this.updateUser(result);
            },
            () => {
                this.onGetUserError();
            }
        );
    }

    private postUser() {
        this.userClaimsService.userClaimsPost().subscribe(
            (result) => {
                this.updateUser(result);
            },
            () => {
                this.onGetUserError();
            }
        );
    }

    private updateUser(user: UserDto) {
        this.currentUser = user;
        this._currentUserSetSubject.next(this.currentUser);
    }

    private onGetUserError() {
        this.router.navigate(["/"]).then((x) => {
            this.alertService.pushAlert(
                new Alert(
                    "There was an error authorizing with the application. The application will force log you out in 3 seconds, please try to login again.",
                    AlertContext.Danger
                )
            );
            setTimeout(() => {
                this.authService.logout();
            }, 3000);
        });
    }

    public refreshUserInfo(user: UserDto) {
        this.updateUser(user);
    }

    public isAuthenticated(): boolean {
        return this.claimsUser != null;
    }

    public handleUnauthorized(): void {
        this.forcedLogout();
    }

    public forcedLogout() {
        if (!this.isCurrentUserBeingImpersonated(this.currentUser)) {
            sessionStorage.authRedirectUrl = window.location.href;
        }
        this.logout();
    }

    public guardInitObservable(): Observable<AuthenticationResult> {
        return this.authService.initialize().pipe(
            concatMap(() => {
                return this.authService.handleRedirectObservable();
            })
        );
    }

    public login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            } else {
                this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    public logout() {
        if (this.isCurrentUserBeingImpersonated(this.currentUser)) {
            this.impersonationService.impersonateStopImpersonationPost().subscribe((response) => {
                this.refreshUserInfo(response);
                this.router.navigateByUrl("/").then((x) => {
                    this.alertService.pushAlert(new Alert(`Finished impersonating`, AlertContext.Success));
                });
            });
        } else {
            this.authService.logout();
        }
    }

    signUp() {
        const signUpRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            redirectStartPage: window.location.origin,
        } as RedirectRequest;

        this.login(signUpRequest);
    }

    public isCurrentUserBeingImpersonated(user: UserDto): boolean {
        if (user) {
            const globalID = this.claimsUser.sub;
            return globalID != user.UserGuid;
        }
        return false;
    }

    public getAuthRedirectUrl() {
        return sessionStorage.authRedirectUrl;
    }

    public setAuthRedirectUrl(url: string) {
        sessionStorage.authRedirectUrl = url;
    }

    public clearAuthRedirectUrl() {
        this.setAuthRedirectUrl("");
    }

    public isUserUnassigned(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.Unassigned && user.IsActive;
    }

    public isUserRoleDisabled(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.Unassigned && !user.IsActive;
    }

    public isCurrentUserNullOrUndefined(): boolean {
        return !this.currentUser;
    }

    public isCurrentUserAnAdministrator(): boolean {
        return this.isUserAnAdministrator(this.currentUser);
    }

    public hasCurrentUserAcknowledgedDisclaimer(): boolean {
        return this.currentUser != null && this.currentUser.DisclaimerAcknowledgedDate != null;
    }

    public doesCurrentUserHaveOneOfTheseRoles(roleIDs: Array<number>): boolean {
        if (roleIDs.length === 0) {
            return false;
        }
        const roleID = this.currentUser && this.currentUser.Role ? this.currentUser.Role.RoleID : null;
        return roleIDs.includes(roleID);
    }

    public isUserAnAdministrator(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.SystemAdmin;
    }

    // todo: rights
    public isUserALandOwner(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.ReadOnly;
    }

    public isCurrentUserALandOwner(): boolean {
        return this.isUserALandOwner(this.currentUser);
    }

    public getCurrentUser(): Observable<UserDto> {
        return race(
            new Observable((subscriber) => {
                if (this.currentUser) {
                    subscriber.next(this.currentUser);
                    subscriber.complete();
                }
            }),
            this.currentUserSetObservable.pipe(first())
        );
    }

    public getCurrentUserID(): Observable<any> {
        return race(
            new Observable((subscriber) => {
                if (this.currentUser) {
                    subscriber.next(this.currentUser.UserID);
                    subscriber.complete();
                }
            }),
            this.currentUserSetObservable.pipe(
                first(),
                map((user) => user.UserID)
            )
        );
    }

    public hasPermission(user: UserDto, permission: PermissionEnum, rights: RightsEnum): boolean {
        const permissionName = PermissionEnum[permission];

        const hasPermission = user && user.Rights && user.Rights[permissionName] ? user.Rights[permissionName][rights] : false;

        return hasPermission;
    }

    public hasOverallPermission(user: UserDto, permission: PermissionEnum, rights: RightsEnum): boolean {
        if (this.hasPermission(user, permission, rights)) {
            return true;
        }

        return false;
    }

    public hasFlag(user: UserDto, flag: FlagEnum): boolean {
        const flagName = FlagEnum[flag];

        const hasFlag = user && user.Flags ? user.Flags[flagName] : false;

        return hasFlag;
    }

    public currentUserHasFlag(flag: FlagEnum) {
        return this.hasFlag(this.currentUser, flag);
    }
}
