<div class="popup-wrapper" [loadingSpinner]="{ isLoading, loadingHeight: 225 }">
    <ng-container *ngIf="data$ | async as data">
        <div class="popup-header">
            <div class="county-title" [title]="county.CountyName">
                <h3>
                    <span class="county-title__name">
                        {{ county.CountyName }}
                    </span>
                </h3>
            </div>
        </div>

        <div class="popup-body">
            <div>
                <dl>
                    <dd>
                        <strong>Total County Acres:</strong>
                        {{ county.TotalAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="county.ReachAcres">
                        <strong>Total Reach Acres:</strong>
                        {{ county.ReachAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="county.RiparianAcres">
                        <strong>Total Riparian Acres:</strong>
                        {{ county.RiparianAcres | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="county.StreamMiles">
                        <strong>Total Stream Miles:</strong>
                        {{ county.StreamMiles | number: "1.0-0" }}
                    </dd>
                    <dd *ngIf="county.PercentTreeCover">
                        <strong>% Tree Cover:</strong>
                        {{ county.PercentTreeCover | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="county.PercentVegetated">
                        <strong>% Vegetated:</strong>
                        {{ county.PercentVegetated | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="county.PercentNonVegetated">
                        <strong>% Non-Vegetated:</strong>
                        {{ county.PercentNonVegetated | percent: "1.0-1" }}
                    </dd>
                    <dd *ngIf="county.ImpassableFishPassageBarriers">
                        <strong>Impassable Fish Passage Barriers:</strong>
                        {{ county.ImpassableFishPassageBarriers | number }}
                    </dd>
                </dl>
            </div>
        </div>

        <div class="popup-footer">
            <a class="btn btn-primary btn-sm" [routerLink]="['/counties/', county.CountyID]">View County</a>
        </div>
    </ng-container>
</div>
