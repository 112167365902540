import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Observable } from "rxjs";
import { Map } from "leaflet";
import * as L from "leaflet";
import { WfsService } from "src/app/shared/services/wfs.service";
import { RiparisMapInitEvent } from "src/app/shared/components/leaflet/riparis-map/riparis-map.component.js";
import { AlertDisplayComponent } from "src/app/shared/components/alert-display/alert-display.component";
import { RiparisMapComponent } from "src/app/shared/components/leaflet/riparis-map/riparis-map.component";
import { WaterResourceInventoryAreaPopupComponent } from "src/app/shared/components/maps/water-resource-inventory-area-popup/water-resource-inventory-area-popup.component";
import { environment } from "src/environments/environment";
import { FormsModule } from "@angular/forms";
import { ContentSectionComponent } from "src/app/shared/components/content-section/content-section.component";
import { NgIf } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "app-water-resource-inventory-area-index",
    templateUrl: "./water-resource-inventory-area-index.component.html",
    styleUrls: ["./water-resource-inventory-area-index.component.scss"],
    standalone: true,
    imports: [ContentSectionComponent, RiparisMapComponent, AlertDisplayComponent, WaterResourceInventoryAreaPopupComponent, FormsModule, NgIf],
})
export class WaterResourceInventoryAreaIndexComponent implements OnInit, OnDestroy {
    public currentUser$: Observable<UserDto>;
    public selectedOverlayScheme: string = "wria";

    constructor(
        private wfsService: WfsService,
        private cdr: ChangeDetectorRef,
        private title: Title
    ) {}

    ngOnDestroy(): void {}

    ngOnInit(): void {
        this.title.setTitle(`Watershed | Map Explorer | Riparian Data Engine`);
    }

    public baseOutlineWithLabelsLayer;
    public overlayLayer;
    public map: L.Map;
    public layerControl: L.layerControl;
    public mapIsReady: boolean = false;
    public choroplethControl: L.Control;
    public choroplethControlID: string = "choroplethControl";

    handleMapReady(event: RiparisMapInitEvent): void {
        this.map = event.map;
        this.layerControl = event.layerControl;
        this.mapIsReady = true;
        const self = this;

        const choroplethControl = L.Control.extend({
            onAdd(map: Map) {
                const domElement = L.DomUtil.get(self.choroplethControlID);
                L.DomEvent.disableClickPropagation(domElement);
                return domElement;
            },
            onRemove(map: Map) {},
        });
        this.choroplethControl = new choroplethControl({
            position: "topleft",
        }).addTo(this.map);

        this.map.on("click", (event: L.LeafletMouseEvent): void => {
            self.map.fireEvent("dataloading");
            self.wfsService.getWriaIDByCoordinate(event.latlng.lng, event.latlng.lat).subscribe((reachPID: string) => {
                const startIndex = reachPID.indexOf("<Riparis:WaterResourceInventoryAreaID>");
                const endIndex = reachPID.indexOf("</Riparis:WaterResourceInventoryAreaID>");
                self.map.fireEvent("dataload");
                if (startIndex < 0 && endIndex < 0) {
                    return;
                }

                const waterResourceInventoryAreaID = reachPID.substring(startIndex + 38, endIndex);

                // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
                L.popup({
                    maxWidth: 250,
                    keepInView: true,
                })
                    .setLatLng(event.latlng)
                    .setContent(
                        `<water-resource-inventory-area-popup-custom-element water-resource-inventory-area-id="${waterResourceInventoryAreaID}"></water-resource-inventory-area-popup-custom-element>`
                    )
                    .openOn(self.map);
            });
        });

        this.baseOutlineWithLabelsLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", {
            layers: "Riparis:Wrias",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "wria_label",
        });

        this.updateOverlayLayer();
        this.cdr.detectChanges();
    }

    public updateOverlayLayer(): void {
        if (this.overlayLayer) {
            this.map.removeLayer(this.overlayLayer);
        }

        if (this.baseOutlineWithLabelsLayer) {
            this.map.removeLayer(this.baseOutlineWithLabelsLayer);
        }

        this.overlayLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", {
            layers: "Riparis:Wrias",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: this.selectedOverlayScheme,
        });
        this.map.addLayer(this.overlayLayer);
        this.map.addLayer(this.baseOutlineWithLabelsLayer);
    }
}
